export const APP_INFO = {
    en: {
        request_e_catalog: 'Request E-Catalog',
        request_e_catalog_instruction: 'To request the Cocktail E-Catalog, please visit our website.',
        useful_information: 'Useful App Information',
        save_to_phone: 'Save to phone',
        save_to_phone_instruction: `If you're using the app on your phone, save it to your home screen for quick access. Simply tap the 'Share' button in your mobile browser and select 'Add to Home Screen' for convenience.`,
    },
    fr: {
        request_e_catalog: 'Demander le E-Catalogue',
        request_e_catalog_instruction: 'Pour demander le E-Catalogue de Cocktail, veuillez visiter notre site web.',
        useful_information: "Informations utiles sur l'application",
        save_to_phone: 'Enregistrer sur le téléphone',
        save_to_phone_instruction: `Si vous utilisez l'application sur votre téléphone, enregistrez-la sur votre écran d'accueil pour un accès rapide. Appuyez simplement sur le bouton 'Partager' dans votre navigateur mobile et sélectionnez 'Ajouter à l'écran d'accueil' pour plus de commodité.`,
    },
    es: {
        request_e_catalog: 'Solicitar E-Catálogo',
        request_e_catalog_instruction: 'Para solicitar el E-Catálogo de Cocktail, por favor visita nuestro sitio web.',
        useful_information: 'Información útil de la aplicación',
        save_to_phone: 'Guardar en el teléfono',
        save_to_phone_instruction: `Si estás utilizando la aplicación en tu teléfono, guárdala en tu pantalla de inicio para un acceso rápido. Simplemente toca el botón 'Compartir' en tu navegador móvil y selecciona 'Agregar a la pantalla de inicio' para mayor comodidad.`,
    },
    ua: {
        request_e_catalog: 'Замовити E-Каталог',
        request_e_catalog_instruction: 'Щоб замовити E-Каталог Cocktail, будь ласка, відвідайте наш веб-сайт.',
        useful_information: 'Корисна інформація про додаток',
        save_to_phone: 'Зберегти на телефон',
        save_to_phone_instruction: `Якщо ви використовуєте додаток на телефоні, збережіть його на домашньому екрані для швидкого доступу. Просто натисніть кнопку 'Поділитися' у вашому мобільному браузері та виберіть 'Додати на домашній екран' для зручності.`,
    },
    pl: {
        request_e_catalog: 'Zamów E-Katalog',
        request_e_catalog_instruction: 'Aby zamówić E-Katalog Cocktail, odwiedź naszą stronę internetową.',
        useful_information: 'Przydatne informacje o aplikacji',
        save_to_phone: 'Zapisz do telefonu',
        save_to_phone_instruction: `Jeśli korzystasz z aplikacji na telefonie, zapisz ją na ekranie głównym dla szybkiego dostępu. Wystarczy kliknąć przycisk 'Udostępnij' w swojej przeglądarce mobilnej i wybrać 'Dodaj do ekranu głównego' dla wygody.`,
    },
    cz: {
        request_e_catalog: 'Požádat o E-Katalog',
        request_e_catalog_instruction: 'Chcete-li požádat o E-Katalog Cocktail, navštivte naše webové stránky.',
        useful_information: 'Užitečné informace o aplikaci',
        save_to_phone: 'Uložit do telefonu',
        save_to_phone_instruction: `Pokud používáte aplikaci na telefonu, uložte ji na domovskou obrazovku pro rychlý přístup. Stačí klepnout na tlačítko 'Sdílet' ve svém mobilním prohlížeči a vybrat 'Přidat na domovskou obrazovku' pro pohodlí.`,
    },
    ru: {
        request_e_catalog: 'Заказать E-Каталог',
        request_e_catalog_instruction: 'Чтобы заказать E-Каталог Cocktail, пожалуйста, посетите наш веб-сайт.',
        useful_information: 'Полезная информация приложения',
        save_to_phone: 'Сохранить на телефон',
        save_to_phone_instruction: `Если вы используете приложение на телефоне, сохраните его на домашний экран для быстрого доступа. Просто нажмите в вашем мобильном браузере кнопку 'Поделиться' и выберите 'Добавить на домашний экран' для удобства доступа.`,
    },
    kz: {
        request_e_catalog: 'E-Каталог сұрау',
        request_e_catalog_instruction: 'Cocktail E-Каталогын сұрау үшін біздің веб-сайтымызға кіріңіз.',
        useful_information: 'Қолданба жайлы пайдалы ақпарат',
        save_to_phone: 'Телефонға сақтау',
        save_to_phone_instruction: `Егер сіз қосымшаны телефонда пайдалансаңыз, оны үйіңіздің бас ашығына сақтаңыз. Ұялы тапсырысты қолдану үшін мобильді браузеріңізде 'Бөлісу' түймесін басыңыз және 'Үйінің басына қосу' таңдаңыз.`,
    },
};
